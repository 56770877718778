import { Card, Form, Col, Row, Select } from 'antd';
import { useGetUserListQuery } from 'services';
import { afterCopyrightStatus } from 'utils';

const AsignItem = ({ status, field, handleSelect, role }) => {
  const { data, isLoading } = useGetUserListQuery({
    'roles[]': role,
  });
  return (
    <Card>
      <Row
        align="middle"
        gutter={24}
      >
        <Col span={18}>{afterCopyrightStatus[status]}</Col>
        <Form.Item
          name={`assignmentID_${status}`}
          hidden
        >
          <input />
        </Form.Item>
        <Col span={6}>
          <Form.Item
            rules={[
              {
                required: true,
                message: 'Lütfen bir kullanıcı seçiniz!',
              },
            ]}
            name={status}
          >
            <Select
              options={data?.['hydra:member']?.map((item) => ({
                label: item.fullName,
                value: item['@id'],
              }))}
              onSelect={(value) => handleSelect(value, field, status)}
              loading={isLoading}
              className="gx-w-100"
            />
          </Form.Item>
        </Col>
      </Row>
    </Card>
  );
};

export default AsignItem;
