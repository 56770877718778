import React, { useMemo, useState } from 'react';

import { DownloadOutlined, FieldTimeOutlined, FileOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Modal, Row, Switch, Upload } from 'antd';
import { useGetArticleContentQuery } from 'services';
import { downloadBlob } from 'utils';
import { fileDownload } from 'fetcher';

const handleBlobDownload = async (fileUrl, fileName) => {
  const { data } = await fileDownload(fileUrl);
  downloadBlob(data, fileName);
};
const contentFile = (fileName, fileUrl) => {
  return (
    <Card>
      <div className="gx-d-flex gx-align-items-center gx-justify-content-between">
        <div className="gx-d-flex gx-fit-text gx-align-items-center">
          <FileOutlined />
          <div className="gx-ml-2">{fileName}</div>
        </div>
        <Button
          type="link"
          icon={<DownloadOutlined className="gx-fs-xl" />}
          onClick={() => handleBlobDownload(fileUrl, fileName)}
        />
      </div>
    </Card>
  );
};

const MediaField = ({ offerID, onAssignStep = false }) => {
  const [form] = Form.useForm();
  const [openModal, setOpenModal] = useState(false);
  const switchValue = Form.useWatch('switch', form);

  const { data } = useGetArticleContentQuery({
    offerID,
  });
  const articleContent = useMemo(() => {
    return data?.contents.find((item) => item.type === 0);
  }, [data]);
  const idCard = useMemo(() => {
    return data?.contents.find((item) => item.type === 1);
  }, [data]);
  const unkaFile = useMemo(() => {
    return data?.contents.find((item) => item.type === 3);
  }, [data]);

  return (
    <>
      <div className="gx-d-flex gx-align-items-center gx-justify-content-between">
        <h3>Madde Dosyası</h3>
        <Button
          icon={<FieldTimeOutlined />}
          type="text"
          onClick={() => setOpenModal(true)}
        >
          Düzenleme Arşivi
        </Button>
      </div>
      <Card className="gx-mt-3">
        {articleContent && (
          <Col>
            <h4>Madde Dosyası</h4> {contentFile(articleContent.media[0].name, articleContent.media[0].fileFullUrl)}
          </Col>
        )}

        {idCard && (
          <>
            <h4>Kimlik Kartı</h4>
            {contentFile(idCard.media[0].name, idCard.media[0].fileFullUrl)}
          </>
        )}
        {unkaFile && (
          <>
            <h4>ÜVKA Dosyası</h4>
            {contentFile(unkaFile.media[0].name, unkaFile.media[0].fileFullUrl)}
          </>
        )}
        {!onAssignStep && (
          <Form
            id="mediaForm"
            form={form}
            layout="vertical"
          >
            <Card>
              <div className="gx-d-flex gx-justify-content-between">
                Medya dosyasında herhangi bir değişiklik yok.
                <Form.Item
                  name="switch"
                  valuePropName="checked"
                  normalize={(value) => (!value ? undefined : value)}
                >
                  <Switch />
                </Form.Item>
              </div>
            </Card>
            {switchValue && (
              <div className="gx-mt-5">
                <Form.Item
                  className="gx-mb-3"
                  name="itemFile"
                >
                  <Upload.Dragger>
                    <p className="ant-upload-drag-icon">
                      <DownloadOutlined />
                    </p>
                    <p className="ant-upload-text">Dosyayı sürükleyip bırakın ya da seçmek için tıklayın</p>
                    <p className="ant-upload-hint">Dosya boyutu 2MB'ı geçemez.</p>
                  </Upload.Dragger>
                </Form.Item>
                <Form.Item name="itemFile">
                  <Upload.Dragger>
                    <p className="ant-upload-drag-icon">
                      <DownloadOutlined />
                    </p>
                    <p className="ant-upload-text">Dosyayı sürükleyip bırakın ya da seçmek için tıklayın</p>
                    <p className="ant-upload-hint">Dosya boyutu 2MB'ı geçemez.</p>
                  </Upload.Dragger>
                </Form.Item>
                <Form.Item
                  className="gx-mt-5"
                  name="description"
                >
                  <Input.TextArea
                    placeholder="Madde içi veri kontrolü için iletmek istediğiniz not varsa yazabilirsiniz."
                    rows={5}
                    showCount
                  />
                </Form.Item>
              </div>
            )}
            <Row
              className="gx-mt-5"
              gutter={24}
              style={{
                flexDirection: 'row',
              }}
            >
              <Col
                sm={24}
                md={12}
              >
                <Button block>Vazgeç</Button>
              </Col>
              <Col
                sm={24}
                md={12}
              >
                <Button
                  form="mediaForm"
                  block
                >
                  Gönder
                </Button>
              </Col>
            </Row>
          </Form>
        )}
      </Card>
      <Modal
        open={openModal}
        onClose={() => setOpenModal(false)}
      >
        <p>Modal Content</p>
      </Modal>
    </>
  );
};

export default MediaField;
