import { Button, Card, Col, Row, Steps } from 'antd';
import { OrderDetailGrid } from 'components/ArticleOrder';
import { useGetAfterCopyRightAssignmentQuery, useGetAsignmentFieldsQuery } from 'services';
import { CircularProgress } from 'components/CircularProgress';
import { afterCopyrightStatus } from 'utils';

import MediaField from './MediaField';
import ContentAsignment from './ContentAsignment';

const AfterCopyright = ({ offer }) => {
  // const [current, setCurrent] = useState(0);
  const { data: assignments, isLoading } = useGetAsignmentFieldsQuery({
    type: 0,
  });
  const { data: afterCopyright } = useGetAfterCopyRightAssignmentQuery({
    articleOffer: offer && offer.id,
    type: 0,
  });

  const afterCopyrightSteps = [
    {
      id: crypto.randomUUID(),
      key: offer.status,
      title: afterCopyrightStatus[offer.status],
    },
    ...(assignments?.map((step, index) => ({
      id: index,
      key: step.status,
      title: afterCopyrightStatus[step.status],
    })) || []),
  ];

  if (isLoading) return <CircularProgress />;

  return (
    <div className="gx-pb-4">
      <Steps
        progressDot
        current={0}
        labelPlacement="vertical"
        items={afterCopyrightSteps.sort((a, b) => a.status - b.status)}
      />
      <Card className="gx-p-3">
        <OrderDetailGrid offer={offer} />
        <MediaField
          offerID={offer && offer.id}
          onAssignStep={offer && offer.status === 27}
          status={offer && offer.status}
        />
        {/* sadece madde atama asamasinda goruntulenecek bir komponent */}
        {offer.status === 27 && (
          <ContentAsignment
            status={offer && offer.status}
            offerURI={offer && offer['@id']}
            assignments={assignments}
            afterCopyright={afterCopyright}
          />
        )}
      </Card>
      <Row gutter={24}>
        <Col
          sm={24}
          md={12}
        >
          <Button block>Geri Gönder</Button>
        </Col>
        <Col
          sm={24}
          md={12}
        >
          <Button block>Telif Sonrası Sürecini Başlat</Button>
        </Col>
      </Row>
    </div>
  );
};

export default AfterCopyright;
